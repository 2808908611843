import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Swoosh from '../images/swoosh-long.svg'
import * as cardStyles from '../styles/modules/team.module.scss'
import * as buttonStyles from '../styles/modules/button.module.scss'

const TeamCard = ({ teamMember }) => {
  const [toggle, setToggle] = useState(false)
  const headshot = getImage(teamMember.node.relationships.user_picture.localFile)
  const headshotAlt = teamMember.node.user_picture.alt
  const firstName = teamMember.node.name
  const name = teamMember.node.field_display_name
  const jobTitle = teamMember.node.field_job_title
  const bio = ReactHtmlParser(teamMember.node.field_bio.processed)

  return (
    <div>
      <div className={`${cardStyles.card} ${toggle ? cardStyles.active : ''}`}>
        <div className={cardStyles.headshot}>
          <GatsbyImage image={headshot} alt={headshotAlt} />
        </div>
        <div className={cardStyles.cardBack}>
          <img src={Swoosh} alt="" className={cardStyles.swoosh} />
          <div className={cardStyles.info}>
            <h3>{name}</h3>
            <h4>{jobTitle}</h4>
            <div className={cardStyles.bio}>{bio}</div>
          </div>
        </div>
      </div>
      <button
        type="button"
        className={`${buttonStyles.button} ${cardStyles.button}`}
        onClick={(e) => {
          e.preventDefault()
          setToggle(!toggle)
        }}
      >
        {toggle ? 'Close' : `About ${firstName}`}
      </button>
    </div>
  )
}

export default TeamCard
