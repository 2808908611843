import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as imageTextStyles from '../styles/modules/image-text.module.scss'

const ImageAndText = ({ text, image, alt = '' }) => (
  <section className={imageTextStyles.grid}>
    <div className={imageTextStyles.text}>
      <p>{text}</p>
    </div>
    <GatsbyImage className={imageTextStyles.image} image={image} alt={alt} />
  </section>
)

export default ImageAndText
