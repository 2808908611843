// extracted by mini-css-extract-plugin
export var active = "team-module--active--7bb1b";
export var bio = "team-module--bio--5a7f9";
export var button = "team-module--button--bd19c";
export var card = "team-module--card--5835e";
export var cardBack = "team-module--cardBack--63236";
export var headshot = "team-module--headshot--30898";
export var info = "team-module--info--3bb88";
export var swoosh = "team-module--swoosh--fac15";
export var teamGrid = "team-module--teamGrid--96589";
export var title = "team-module--title--16e43";