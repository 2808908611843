import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import Team from '../components/team'
import ImageAndText from '../components/image-and-text'

const AboutPage = () => {
  const { about } = useStaticQuery(
    graphql`
      {
        about: configPagesRedfinSettings {
          field_about_text
          field_about_image {
            alt
          }
          relationships {
            field_about_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    `
  )
  const image = getImage(about?.relationships?.field_about_image?.localFile)
  const imageAlt = about?.field_about_image?.alt || ''
  const text = about?.field_about_text || ''

  return (
    <>
      <SEO title="About" />
      <div className="contentContainer">
        <h1>About</h1>
        <ImageAndText text={text} image={image} alt={imageAlt} />
        <Team />
      </div>
    </>
  )
}

export default AboutPage
