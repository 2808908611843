import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import TeamCard from './team-card'
import * as teamStyles from '../styles/modules/team.module.scss'

const Team = () => (
  <StaticQuery
    query={graphql`
      {
        allUserUser(filter: { field_website: { eq: true } }) {
          edges {
            node {
              id
              name
              field_display_name
              field_job_title
              field_last_name
              field_bio {
                processed
              }
              path {
                alias
              }
              user_picture {
                alt
              }
              relationships {
                user_picture {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        transformOptions: { grayscale: true }
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const teamMemberEdges = data.allUserUser.edges

      const sortedTeamMembers = teamMemberEdges.sort((a, b) =>
        a.node.field_last_name > b.node.field_last_name ? 1 : -1
      )

      return (
        <section>
          <h2 className={teamStyles.title}>Meet the team</h2>
          <div className={teamStyles.teamGrid}>
            {sortedTeamMembers.map((teamMember, index) => (
              <TeamCard teamMember={teamMember} key={index} />
            ))}
          </div>
        </section>
      )
    }}
  />
)

export default Team
